@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'RadwaveOutline';
  src: local('RadwaveFont-Outline'),
    url(./assets/fonts/RadwaveFont-Outline.otf) format('opentype');
}

@font-face {
  font-family: 'RadwaveRegular';
  src: local('RadwaveFont-Regular'),
    url(./assets/fonts/RadwaveFont-Regular.otf) format('opentype');
}

::-webkit-scrollbar {
  @apply w-0;
}

@layer base {
  html {
    height: 100%;
  }
  body {
    height: 100%;
    @apply font-kumbh;

    background-color: rgb(243 244 246);
    background-color: white;

    background-image: linear-gradient(#e0e0e0 1px, transparent 1px),
      linear-gradient(90deg, #e0e0e0 1px, transparent 1px),
      linear-gradient(#f0f0f0 1px, transparent 1px),
      linear-gradient(90deg, #f0f0f0 1px, transparent 1px);
    background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
    background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  }

  main {
    max-width: 80rem;
    margin: auto;
    background: white;
    border: #292d344d solid 1px;
    margin-top: 2.45rem;
  }

  #root {
    height: 100%;
  }
}

.list-grid {
  @apply after:absolute after:w-full after:bottom-0 after:left-0 after:bg-bottom-gradient after:py-12;
}
